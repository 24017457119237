<template lang="pug">
.container.mt-3
  app-form(@submit.prevent='submit' :is-saving='saving')
    field(label='Cliente' field='client' required)
      app-select(id='client' :items='clients' v-model.number='location.clientId' required)
    field(label='Nombre' field='name' required)
      input#name.input(type='text' placeholder='Nombre del sitio' min='0' required v-model='location.name')
    field(field='name')
      b-checkbox(v-model='location.public' disabled) Publico
    //- field(label='Cliente' field='client')
    //-   app-select(id='client' :items='clients' v-model.number='contract.clientId' :disabled='!!contract.id')
    //- field(label='Tipo' field='typeOfCharge')
    //-   app-select(id='typeOfCharge' :enum='contractTypeEnum' :enum-names='contractTypeDescriptions' v-model.number='contract.typeOfCharge' :disabled='!!contract.id' required)
    //- field(label='Frec. de cobro' field='chargeFrequency')
    //-   app-select(id='chargeFrequency' :enum='chargeFrequencyTypeEnum' :enum-names='chargeFrequencyTypeDescriptions' v-model.number='contract.chargeFrequency' :disabled='!!contract.id' required)
    field(label='Código' field='code')
      input#code.input(type='number' step='1' placeholder='Código de etiqueta NFC' min='0' required v-model.number='location.code')
    field(label='Agrupador' field='group')
      input#group.input(type='text' placeholder='Ejm: Sitios del sur' min='0' v-model='location.group')
    //- field(label='Frecuencia (mes)' field='frequency')
    //-   input#frequency.input(type='number' step='1' placeholder='Valor en meses' min='0' required v-model.number='contract.frequency')
</template>

<script>
import clientsService from '@/services/clients.service'
import locationsService from '@/services/locations.service'
import { inPoint } from '@/router/routes'
import toastService from '@/services/toast.service'
// import locationsService from '@/services/locations.service'
// import toastService from '@/services/toast.service'

export default {
  data () {
    return {
      clients: [],
      location: {
        public: true
      },
      saving: false
    }
  },
  async created () {
    const result = await clientsService.getAll()
    if (result) {
      this.clients = result
    }
    if (this.$route.query.code) {
      this.location.code = +this.$route.query.code
    }
  },
  methods: {
    async submit () {
      const location = {
        ...this.location,
        lat: 0,
        lng: 0,
        icon: 0,
        public: true
      }
      const result = await locationsService.save(location)
      if (result) {
        toastService.show('Sitio guardado correctamente')
        this.$router.push({ name: inPoint.name })
      }
    }
  }
}
</script>
